.scoreboard-entry {
    max-width: 80%;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin: 5%;
}

.scoreboard-entries-container {
    height: calc(60vh);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 15px;
}

.scoreboard-entry-position {
    min-width: 30%;
}

.scoreboard-entry-user {
    min-width: 70%;
    left: 0;
}

.placement-logo {
    max-height: 30px;
    max-width: 100px;
    align-self: center;
}

.scoreboard-text {
    color: black;
    font-size: calc(4px + 1vmin);
}