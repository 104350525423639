.model-text {
    color: black bol;
    font-size: calc(.5px + 2vmin);
    text-align: center;
}

.model-heading {
    text-align: center;
    font-size: calc(12.5px + 1.5vmin);
    font-weight: bold;
}

.model-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.single-input-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.single-input-form-container-calender {
    display: flex;
    flex-direction: column;
}


.input-form-container {
    height: inherit;
    min-width: 70%;
    max-width: 70%;
    margin-left: auto;
}

.input-form-container-type {
    height: inherit;
    min-width: 55%;
    max-width: 55%;
    align-self: center;
}

.input-form-container-calender {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.input-form-text-container {
    max-width: 30%;
    min-width: 30%;
    height: inherit;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input-form-text-container-type{
    max-width: 45%;
    min-width: 45%;
    height: inherit;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input-form-text-container-calender {
    max-width: 100%;
    min-width: 100%;
    height: inherit;
    text-align: center;
    display: flex;
    justify-content: center;
}

.input-comp-type-radio {
    padding-right: 10%;
    padding-left: 10%;
}

.input-comp-type-radio-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.input-comp-type-radio-container-type {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
}

.modal-body-text {
    align-self: center;
    color: black;
    font-size: calc(5px + 1.25vmin);
}