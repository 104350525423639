.status-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    align-self: center;
    border: 1.5px;
    border-color: white;
    border-style: solid;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
}

.dropdown-item-container {
    flex-direction: row;
    display: flex;
}

.hover-text {   
    margin-left: 20px;
    color: black;
    font-weight: bold;
  /* font-size: calc(4px + 2vmin); */
}