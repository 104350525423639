.game-container {
    text-align: center;
    width: 100%;
    flex-direction: column;
    display: flex;
}

.game-header {
    justify-content: center;
    width: 100%;
    flex-direction: row;
    display: flex;
}

.show-onscreen-keyboard-button {
    margin-right: auto;
    margin-left: 15px;
}

.text {
    align-self: center;
    color: black;
    font-size: calc(5px + 2vmin);
}

.text-hint {
    align-self: center;
    color: black;
    font-size: calc(5px + 2vmin);
    margin-right: 50%;
}

.letter-input {
    min-width: 10%;
    font-size: calc(10px + 2vmin);
}


.letter-input-container {
    flex-direction: row;
    display: flex;
    min-height: calc(40px + 2vmin);
    max-width: calc(90% - 15px);
    min-width: calc(90% - 15px);
    background-color: grey;
    justify-content: space-evenly;
    overflow: hidden;
    white-space: nowrap;
    border: 5px;
    border-radius: 15px;
    border-color: rgb(25, 133, 161);
    border-style: solid;
}

.letter-area-container {
    flex-direction: row;
    display: flex;
}

.letter-button-container {
    max-width: calc(15px + 10%);
    min-width: calc(15px + 10%);
    margin: auto;
}

.guess-word-container {
    flex-direction: row;
    max-width: calc(90% - 15px);
    min-width: calc(90% - 15px);
    display: flex;
    justify-content: space-evenly;
    border: 5px;
    border-radius: 15px;
    border-color: rgba(25, 133, 161, 0);
    border-style: solid;
}

.guess-word-container-full {
    flex-direction: row;
    max-width: 100%;
    min-width: 100%;
    display: flex;
}

.guess-word-container-info {
    max-width: calc(15px + 10%);
    min-width: calc(15px + 10%);
    margin: auto;
}

.guess-button {
    max-width: 80%;
    min-width: 80%;
}

.on-screen-keyboard {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.letter-input-blinking {
    min-width: 10%;
    font-size: calc(10px + 2vmin);
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.status-circle-key {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    align-self: center;
    border: 1.5px;
    border-color: black;
    border-style: solid;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
}

.status-circle-key-text {
    margin-top: 10px;
    align-self: center;
    color: black;
    font-size: calc(4px + 1.5vmin);
}

.status-circle-key-container {
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes spinLetter {
    from {
        -moz-transform: rotateX(0deg);
    }

    to {
        -moz-transform: rotateX(1440deg);
    }
}

@-webkit-keyframes spinLetter {
    from {
        -webkit-transform: rotateX(0deg);
    }

    to {
        -webkit-transform: rotateX(1440deg);
    }
}

@keyframes spinLetter {
    from {
        transform: rotateX(0deg);
    }

    to {
        transform: rotateX(1440deg);
    }
}


@-moz-keyframes colourTransform-green {
    from {
        -moz-color: black;
    }

    to {
        -moz-color: green;
    }
}

@-webkit-keyframes colourTransform-green {
    from {
        -webkit-color: black;
    }

    to {
        -webkit-color: green;
    }
}

@keyframes colourTransform-green {
    from {
        color: black;
    }

    to {
        color: green;
    }
}

@-moz-keyframes colourTransform-yellow {
    from {
        -moz-color: black;
    }

    to {
        -moz-color: yellow;
    }
}

@-webkit-keyframes colourTransform-yellow {
    from {
        -webkit-color: black;
    }

    to {
        -webkit-color: yellow;
    }
}

@keyframes colourTransform-yellow {
    from {
        color: black;
    }

    to {
        color: yellow;
    }
}

@-moz-keyframes colourTransform-red {
    from {
        -moz-color: black;
    }

    to {
        -moz-color: red;
    }
}

@-webkit-keyframes colourTransform-red {
    from {
        -webkit-color: black;
    }

    to {
        -webkit-color: red;
    }
}

@keyframes colourTransform-red {
    from {
        color: black;
    }

    to {
        color: red;
    }
}