.Main {
    text-align: center;
}

.Container {
    background-color: lightgray;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.input-container {
    background-color: grey;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.game-area-container {
    height: 100%;
    flex-direction: row;
    display: flex;
}

.game-play-container {
    height: inherit;
    width: 100%;
}

.game-scoreboard-container {
    width: 20%;
    min-width: 20%;
    min-height: 80%;
    max-height: 80%;
    margin-top: 1%;
    border: 3px;
    border-color: black;
    border-style: solid;
    justify-content: space-evenly;
    margin-right: 10px;
}

.game-scoreboard-header-container {
    height: 8%;
}

.logo {
    max-height: 80px;
}

.text {
    min-height: 5%;
    color: black;
    font-size: calc(5px + 2vmin);
}

.text-word-definition {
    color: black;
    align-self: center;
    text-align: center;
    font-size: calc(2px + 1.5vmin);
}

.button-sign-out-container {
    margin-left: "6px";
    height: "250px"
}

.button-text {
    color: red;
    font-size: calc(10px + 2vmin);
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: lightgrey;
}

::-webkit-scrollbar-thumb {
    background: grey;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(100, 100, 100);
}