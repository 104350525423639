.results-container {
    padding-top: 10px;
}

.results-podium {
    padding-bottom: 40px;
    margin-left: 5%;
    min-width: 90%;
    max-width: 90%;
}

.results-podium-lower-tier {
    display: flex;
}

.results-podium-placement-gold {
    max-width: 25%;
    min-width: 25%;
    display: flex;
    margin-left: 40%;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.results-podium-placement-silver {
    display: flex;
    flex-direction: column;
    margin-left: 7.5%;
    max-width: 25%;
    min-width: 25%;
}

.results-podium-placement-bronze {
    display: flex;
    flex-direction: column;
    margin-left: 40%;
    max-width: 25%;
    min-width: 25%;
}

.podium-logo-gold {
    max-height: 60px;
    max-width: 200px;
    align-self: center;
}

.podium-logo-silver {
    max-height: 60px;
    max-width: 200px;
    align-self: center;
}

.podium-logo-bronze {
    max-height: 60px;
    max-width: 200px;
    align-self: center;
}

.text-podium {
    color: black;
    font-size: calc(2px + 2vmin);
}