.model {
    color: rebeccapurple;
    text-align: center;
}

.model-text {
    color: black bol;
    font-size: calc(.5px + 2vmin);
    text-align: center;
}

.model-heading {
    text-align: center;
    font-size: calc(12.5px + 1.5vmin);
    font-weight: bold;    
}

.model-footer {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.single-input-form-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly;  */
}

.single-input{
    /* align-self: flex-start; */
    margin-left: 50%;
}

.input-form-container{
    height: inherit;
    width: 60%;
    padding-left: 15%;
}

.input-form-text-container{
    width: 40%;
    height: inherit;
    text-align: center;
    /* padding-top: 2%; */
}