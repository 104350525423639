.title {
    font-size: calc(15px + 2vmin);
}

.text-comp-info {
    padding-left: 25px;
    color: black;
    font-size: calc(2px + 2vmin);
}

.game-info-container {
    display: flex;
}

.game-info-container-left {
    align-items: center;
    margin: 0 auto;
}

.game-info-container-right {
    display: flex;
    flex-direction: column;
    padding-right: 5%;
    margin-left: auto;
}

.game-status-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.game-status-text {
    padding-top: 12.5px;
    min-height: 5%;
    color: black;
    font-size: calc(5px + 2vmin);
}