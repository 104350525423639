  .spin {
    width: 120px;
    height: 120px;
    display: inline-block;
    position: absolute;
    top: 39%; 
    left: 42.5%; 
    border-radius: 50%;
    animation: spin 2s ease-in-out infinite;
    -webkit-animation: spin 1.5s ease-in-out infinite;
  }
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .spin-inline {
    width: 50px;
    height: 50px;
    top: 39%; 
    left: 42.5%; 
    border-radius: 50%;
    animation: spin 2s ease-in-out infinite;
    -webkit-animation: spin 1.5s ease-in-out infinite;
  }
  @keyframes spin-inline {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin-inline {
    to {
      -webkit-transform: rotate(360deg);
    }
  }